import fetch from "../utils/fetch";


// 轮播图
export function getBannerList(data) {
  return fetch({
    url: '/api/user/banner',
    method: 'post',
    data: data
  });
}
// 文件上传
export function fileUpload(data) {
  return fetch({
    url: '/api/user/upload',
    method: 'post',
    data: data
  });
}
// 公告
export function getNoticeList(data) {
  return fetch({
    url: '/api/user/announcement-lists',
    method: 'post',
    data: data
  });
}