<template>
  <div class="index-page">
    <div class="top-banner flex">
      <div class="left">
        <Swipe
          ref="swipe"
          :autoplay="3000"
          :loop="true"
          :show-indicators="false"
          :height="310"
          :width="720"
        >
          <SwipeItem v-for="(item, i) in bannerList" :key="i">
            <img :src="item.Img" alt="" @dragstart.prevent />
          </SwipeItem>
        </Swipe>
      </div>
      <div class="right">
        <div class="flex">
          <div class="banner">
            <Swipe
              ref="swipe"
              :autoplay="3000"
              :loop="true"
              :show-indicators="false"
              :height="150"
            >
              <SwipeItem v-for="(item, i) in bannerList2" :key="i">
                <img :src="item.Img" alt="" @dragstart.prevent />
              </SwipeItem>
            </Swipe>
          </div>
          <div class="banner">
            <Swipe
              ref="swipe"
              :autoplay="3000"
              :loop="true"
              :show-indicators="false"
              :height="150"
            >
              <SwipeItem v-for="(item, i) in bannerList2" :key="i">
                <img :src="item.Img" alt="" @dragstart.prevent />
              </SwipeItem>
            </Swipe>
          </div>
        </div>
        <div class="flex mt-10">
          <div class="banner">
            <Swipe
              ref="swipe"
              :autoplay="3000"
              :loop="true"
              :show-indicators="false"
              :height="150"
            >
              <SwipeItem v-for="(item, i) in bannerList2" :key="i">
                <img :src="item.Img" alt="" @dragstart.prevent />
              </SwipeItem>
            </Swipe>
          </div>
          <div class="banner">
            <Swipe
              ref="swipe"
              :autoplay="3000"
              :loop="true"
              :show-indicators="false"
              :height="150"
            >
              <SwipeItem v-for="(item, i) in bannerList2" :key="i">
                <img :src="item.Img" alt="" @dragstart.prevent />
              </SwipeItem>
            </Swipe>
          </div>
        </div>
      </div>
    </div>

    <div class="category">
      <div class="page-title">
        <div class="title">{{ $t('tui-jian-fen-lei') }}</div>
        <div class="more" @click="toPath('category')">
          {{ $t('quan-bu') }}<i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <div class="category-list">
        <Swipe
          ref="swipe"
          :autoplay="3000"
          :loop="false"
          :show-indicators="false"
          :width="110"
          :height="88"
        >
          <SwipeItem v-for="(item, i) in categoryList" :key="i">
            <div
              class="category-box flex-column-center"
              @click="toGoodsList(item)"
            >
              <div class="img">
                <img
                  :src="item.Image || categoryUrl"
                  alt=""
                  @dragstart.prevent
                />
              </div>
              <div class="label">{{ item.title }}</div>
            </div>
          </SwipeItem>
        </Swipe>
      </div>
    </div>

    <div class="newest-products">
      <div class="page-title">
        <div class="title" id="link1">{{ $t('mei-ri-shang-xin') }}</div>
        <div class="more">
          {{ $t('geng-duo') }}<i class="el-icon-arrow-right"></i>
        </div>
      </div>

      <goodsList :list="newestList"></goodsList>
    </div>

    <div class="join-banner">
      <div class="info flex-column-center">
        <div class="label">{{ $t('cheng-wei-shang-jia') }}</div>
        <div class="label">{{ $t('gong-fen-yong-jin') }}</div>
        <div class="label bold">{{ $t('zui-gao-100000') }}</div>
        <el-button @click="toRegister">{{ $t('li-ji-jia-ru') }}</el-button>
      </div>
    </div>

    <div class="recommend-products">
      <div class="page-title">
        <div class="title" id="link2">{{ $t('tui-jian-chan-pin') }}</div>
      </div>

      <goodsList :list="recommendList"></goodsList>
    </div>

    <div class="shop-banner">
      <div class="page-title">
        <div class="title" id="link3">{{ $t('tui-jian-dian-pu') }}</div>
      </div>
      <div class="shop-list">
        <Swipe
          ref="swipe"
          :autoplay="3000"
          :loop="false"
          :show-indicators="false"
          :width="430"
          :height="112"
        >
          <SwipeItem v-for="(item, i) in shopList" :key="i">
            <div class="shop flex-center">
              <div class="img">
                <img :src="item.Logo" alt="" @dragstart.prevent />
              </div>
              <div class="flex-1">
                <div class="name">{{ item.Name }}</div>
                <div class="flex-center-between">
                  <div>
                    <!-- <div class="info">商品：0</div> -->
                    <div class="info">
                      {{ $t('xiao-liang') }}
                      {{ item.TotalDeal }}
                    </div>
                    <div class="info">
                      {{ $t('ping-fen') }}
                      {{ item.GoodsScore }}
                    </div>
                  </div>
                  <el-button @click="toShop(item)"
                    >{{ $t('fang-wen-shang-dian')
                    }}<i class="el-icon-arrow-right"></i
                  ></el-button>
                </div>
              </div>
            </div>
          </SwipeItem>
        </Swipe>
      </div>
    </div>

    <div class="recommend-products">
      <div class="page-title">
        <div class="title" id="link4">{{ $t('re-xiao-chan-pin') }}</div>
      </div>

      <goodsList :list="hotGoodsList"></goodsList>
    </div>

    <div class="bottom-banner">
      <div class="flex-column-start-center info">
        <div class="title">
          {{ $t('kua-jing-dian-shang-pi-fa-shang-chang') }}
        </div>
        <div class="desc">{{ $t('pin-pai-te-mai-zhe-kou-bu-ting') }}</div>
        <div class="flex btn-box">
          <div class="btn flex-center">
            <img :src="androidUrl" alt="" />
            {{ $t('an-zhuo-xia-zai') }}
          </div>
          <div class="btn flex-center">
            <img :src="iosUrl" alt="" />
            {{ $t('ping-guo-xia-zai') }}
          </div>
        </div>
      </div>
    </div>

    <bottomLink></bottomLink>

    <div class="left-link">
      <div
        class="link"
        v-for="(item, i) in leftLinkList"
        :key="i"
        :class="[i === 4 ? 'active top' : ''].join(' ')"
        @click="pageScroll(item, i)"
      >
        <div class="icon">
          <img :src="item.icon" alt="" />
        </div>
        <div class="label">
          {{ item.label }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getBannerList } from '@/api/index'
import { shopProductList, getShopList } from '@/api/shop'
import '@vant/touch-emulator'
import bottomLink from '@/components/bottomLink'
import goodsList from '@/components/goodsList'
import { SwipeItem, Swipe } from 'vant'
export default {
  name: 'about',
  components: {
    Swipe,
    SwipeItem,
    goodsList,
    bottomLink
  },
  data() {
    return {
      androidUrl: require('@/assets/imgs/icon-android.png'),
      iosUrl: require('@/assets/imgs/icon-ios.png'),
      categoryUrl: require('@/assets/imgs/category1.png'),
      bannerList: [],
      bannerList2: [],
      newestList: [], //每日上新
      recommendList: [], //推荐产品
      goodsList: [], //
      shopList: [],
      hotGoodsList: [], //热销产品
      leftLinkList: [
        {
          icon: require('@/assets/imgs/icon-link1.png'),
          label: this.$t('mei-ri-shang-xin-0')
        },
        {
          icon: require('@/assets/imgs/icon-link2.png'),
          label: this.$t('tui-jian-chan-pin-0')
        },
        {
          icon: require('@/assets/imgs/icon-link3.png'),
          label: this.$t('tui-jian-dian-pu-0')
        },
        {
          icon: require('@/assets/imgs/icon-link4.png'),
          label: this.$t('re-xiao-chan-pin-0')
        },
        {
          icon: require('@/assets/imgs/icon-link5.png')
        }
      ]
    }
  },
  computed: {
    appName() {
      return this.$store.state.appName
    },
    lang() {
      return this.$store.state.lang
    },
    categoryList() {
      return this.$store.state.categoryList
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.$store.dispatch('initCategory', this.lang)
      getBannerList().then((res) => {
        this.bannerList = res.data.Items.filter((v) => {
          return v.IsMain == 1
        })
        this.bannerList2 = res.data.Items.filter((v) => {
          return v.IsMain != 1
        })
      })

      // 新品首发
      shopProductList({
        current: 1,
        pageSize: 24
      }).then((res) => {
        this.newestList = res.data.Items
      })

      // 推荐产品
      shopProductList({
        current: 1,
        pageSize: 24,
        Guess: 1
      }).then((res) => {
        this.recommendList = res.data.Items
      })

      // 热销产品
      shopProductList({
        current: 1,
        pageSize: 24,
        Sort: 'sales'
      }).then((res) => {
        this.hotGoodsList = res.data.Items
      })

      // 店铺列表
      getShopList({
        Recommend: 1
      }).then((res) => {
        this.shopList = res.data.Items
      })
    },
    pageScroll(data, i) {
      if (i === 4) {
        // toTop
        document.body.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      } else {
        let id = `link${i + 1}`
        let el = document.getElementById(id)
        let top = el.offsetTop
        document.body.scrollTo({
          top: top,
          behavior: 'smooth'
        })
      }
    },
    toShop(data) {
      this.$router.push({
        name: 'shopInfo',
        query: {
          id: data.ID
        }
      })
    },
    toPath(path) {
      this.$router.push({
        name: path
      })
    },
    toGoodsList(data) {
      this.$router.push({
        name: 'commodity',
        query: {
          id: data.ID
        }
      })
    },
    toRegister() {
      let url = `${window.location.origin}/#/register`
      let a = document.createElement('a')
      a.href = url
      a.target = '_blank'
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    }
  }
}
</script>
<style lang="less">
</style>
